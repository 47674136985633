import "./import/modules";
import "./import/components";

// alert(1);
function createCircleChart(percent, color, size, stroke, title) {
    let svg = `<svg class="mkc_circle-chart" viewbox="0 0 36 36" width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
        <path class="mkc_circle-bg" stroke="#E7F0FF" stroke-width="${stroke}" fill="none" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"/>
        <path class="mkc_circle" stroke="${color}" stroke-width="${stroke}" stroke-dasharray="${percent},100"  fill="none"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
        <text class="mkc_info" x="50%" y="42%" alignment-baseline="central" text-anchor="middle" font-size="4">${title}</text>
        <text class="mkc_info" x="52%" y="60%" alignment-baseline="central" text-anchor="middle" font-size="6" font-weight="600">${percent}%</text>
    </svg>`;
    return svg;
}

let charts = document.getElementsByClassName('mkCharts');

for (let i = 0; i < charts.length; i++) {
    let chart = charts[i];
    let percent = chart.dataset.percent;
    let title = ('title' in chart.dataset) ? chart.dataset.title : "123";
    let color = ('color' in chart.dataset) ? chart.dataset.color : "#2F4F4F";
    let size = ('size' in chart.dataset) ? chart.dataset.size : "100";
    let stroke = ('stroke' in chart.dataset) ? chart.dataset.stroke : "1";
    charts[i].innerHTML = createCircleChart(percent, color, size, stroke, title);
}



$(document).ready(function () {
    $(document).on('click', '.js-header-menu', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $(this).closest('.sidebar').toggleClass('active');
        $('.js-overlay').fadeToggle()
    });

    if($('#myChart').length>0){
        const ctx = document.getElementById('myChart');
    
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['This month', 'Last month', '2 months ago'],
                datasets: [{
                    label: false,
                    data: [0, 0, 0],
                    borderWidth: 0,
                    backgroundColor: '#438AFE'
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                }
            }
        });
    }

    $(document).on('click', '.js-overlay', function (e) {
        e.preventDefault();
        $(this).fadeOut();
        $('.sidebar').removeClass('active');
        $('.js-header-menu').removeClass('active');
    });

    $(document).on('click', '.js-stat-tab', function (e) {
        e.preventDefault();
        let stats = $(this).data('stat').split(",");
        myChart.data.datasets[0].data = stats;
        myChart.update();
        $('.js-stat-tab').removeClass('active');
        $(this).addClass('active');
    });
    $('.js-stat-tab').eq(0).click();


    $(document).on('click', '.js-show-password', function (e) {
        e.preventDefault();
        let input = $(this).siblings('input');
        var type = input.attr("type");
        if (type === 'password') {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
    $(document).on('change', '.js-select-table-row', function (e) {
        // if($(this).is(':checked')){
            $(this).closest('tr').toggleClass('selected')
        // }
    });
})